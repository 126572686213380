@import '../../../assets/styles/variables';

.ServicesPanel {
  .IconWrap {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    border-radius: 50%;
    padding: (4/$df-fs)*$rem;

    &.Active {
      opacity: 0.7;
    }
  }
  .AppsList {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    column-gap: (8/$df-fs)*$rem;
    row-gap: (8/$df-fs)*$rem;
    grid-auto-flow: row;
    padding: (16/$df-fs)*$rem;

    .AppItem {
       &,&:link {
        display: flex;
        color: $white;
        font-weight: $weightSemibold;
        font-size: $fontSizeBase;
        align-items: center;
        justify-content: center;
        width: (100/$df-fs)*$rem;
        height: (62/$df-fs)*$rem;
        border-radius: $radiusBase;
      }
      &:hover {
        color: $white;
        outline: none;
        border: 0;
      }
      &.oms {
        background-color: rgb(237, 28, 36);
      }
      &.ecom {
        background-color: rgb(96, 147, 93);
      }
      &.dlv {
        background-color: rgb(241, 211, 2);
      }
      &.mdm {
        background-color: rgb(183, 148, 146);
      }
      &.vendor {
        background-color: rgb(62, 146, 204);
      }
      &.tmk {
        background-color: rgb(61, 52, 139);
      }
      &.pms {
        background-color: rgb(252, 122, 87);
      }
    }
  }
  .PanelFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: (8/$df-fs)*$rem 0 (16/$df-fs)*$rem;

    .FullAppsBtn {
      display: inline-block;
      color: $secondaryColor;
    }
  }
}
