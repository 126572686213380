@import '../../assets/styles/variables';

.Header {
  height: $mainNavHeight;
  padding: 0 $gridSpacingX;
  display: flex;
  justify-content: space-between;
  color: $headerColor;
  background-color: $headerBg;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 9;
}
.Brand {
  height: 82%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: (20/$df-fs)*$rem;

  > img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &, &:link {
    color: $white;
  }
  &:hover {
    color: $white;
  }
}
.HeaderNav {
  flex: 1 1 0;
  flex-basis: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > ul {
    display: flex;
    align-items: stretch;
  }
  > ul > li {
    display: flex;
    position: relative;
    margin-right: $gridSpacingX;
  }
  .OrderBtn {
    font-weight: $weightSemibold;
    background-color: $primaryColor;
    box-shadow: 0px 2px 4px rgba(204, 0, 0, 0.2), 0px 4px 8px rgba(237, 28, 36, 0.2);
    border-radius: $radiusBase;
    &:hover {
      box-shadow: none;
      background-color: $primaryHoverColor;
    }
    &:focus {
      box-shadow: none;
      background-color: $primaryPressColor;
    }
  }
}
.RightNav {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > li + li {
    padding-left: (24/$df-fs)*$rem;
  }
}
