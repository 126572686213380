@import '../../assets/styles/variables';

.Wrap {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  align-items: stretch;
  grid-row-gap: $gridSpacingY;
}
