@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0 $gridSpacingX;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.Dashboard {
  width: 100%;
  max-width: (875/$df-fs)*$rem;
  margin: 0 auto;
  padding: $gridSpacingX 0;
}
.Greeting {
  font-size: $fontSizeXLg;
  > strong {
    font-weight: $weightSemibold;
  }
}
.Modules {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax((274/$df-fs)*$rem, 1fr));
  column-gap: $gridSpacingX*1.5;
  row-gap: $gridSpacingY*1.5;
  padding-top: $gridSpacingY*2;
  align-items: stretch;

  .ModuleItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    color: $textColor;

    .ModuleItemInner {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      padding: $gridSpacingY*1.5 $gridSpacingY;
      border-radius: $radiusSm;
      background-color: $white;
      border: 1px solid $borderColor;
      align-items: stretch;
      transition: all ease-in-out 0.1s;

      .Icon {
        font-size: (24/$df-fs)*$rem;
        width: (24/$df-fs)*$rem;
        height: auto;
      }
      > p {
        padding-top: $gridSpacingY*1.5;
        font-size: $fontSizeLg;
      }
      > span {
        color: $textMuted;
        font-size: $fontSizeSm;
      }
    }

    &:hover, &:active, &:focus {
      .ModuleItemInner {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
        border-color: $white;
      }
    }

    &.Disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
